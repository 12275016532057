<template>
  <div class="pb-56">
    <!-- 首頁就是投注紀錄 wager -->
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>

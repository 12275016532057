<template>
  <div>
    <v-container>
      <v-row class="title--text">
        <v-col
          cols="2"
          class="pb-4 pt-2 d-flex align-center justify-center"
        >
          <Icon
            data="@icon/date.svg"
            width="24"
            height="24"
            class="icon--text"
          />
        </v-col>

        <!-- 開始日期 -->
        <v-col
          cols="4"
          class=" pt-2 px-0"
        >
          <v-dialog
            ref="dialogFrom"
            v-model="dialog.from"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <!-- input -->
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="date.from"
                hide-details
                dense
                single-line
                outlined
                v-bind="attrs"
                readonly
                v-on="on"
              />
            </template>

            <!-- 日期選擇器 -->
            <v-date-picker
              v-model="date.from"
              class="bg"
              header-color="bg"
              color="primary"
              scrollable
              :min="min"
              :max="max"
            >
              <v-spacer />
              <v-btn
                text
                color="subtitle"
                class="subtitle-1 font-weight-medium"
                @click="dialog.from = false"
              >
                {{ $t('global.cancel') }}
              </v-btn>
              <v-btn
                class="subtitle-1 font-weight-medium"
                text
                color="primary"
                @click="$refs.dialogFrom.save(date)"
              >
                {{ $t('global.confirmation') }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="2"
          class="pb-4 pt-2 font-weight-bold d-flex align-center justify-center"
        >
          -
        </v-col>

        <!-- 結束日期 -->
        <v-col
          cols="4"
          class="pt-2 px-0"
        >
          <v-dialog
            ref="dialogTo"
            v-model="dialog.to"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <!-- input -->
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="date.to"
                hide-details
                readonly
                dense
                single-line
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <!-- 日期選擇器 -->
            <v-date-picker
              v-model="date.to"
              class="bg"
              header-color="bg"
              color="primary"
              scrollable
              :min="min"
              :max="max"
            >
              <v-spacer />
              <v-btn
                text
                color="subtitle"
                class="subtitle-1 font-weight-medium"
                @click="dialog.to = false"
              >
                {{ $t('global.cancel') }}
              </v-btn>
              <v-btn
                class="subtitle-1 font-weight-medium"
                text
                color="primary"
                @click="$refs.dialogTo.save(date)"
              >
                {{ $t('global.confirmation') }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: {
      from: false,
      to: false,
    },

    date: {
      from: new Date().toISOString().substr(0, 10),
      to: new Date().toISOString().substr(0, 10),
    },
  }),

  computed: {
    /**
     * 最大 可選日期
     * @date 2021-04-27
     * @returns {string} 今天日期
     */
    max() {
      return new Date().toISOString().substr(0, 10)
    },

    /**
     * 最小 可選日期
     * @date 2021-04-27
     * @returns {string} 30天之前日期
     */
    min() {
      const minDay = this.$day()
        .subtract(29, 'day')
        .format('YYYY-MM-DD')
      return minDay
    },

    /**
     * 結束日期是否比開始日期大
     * @date 2021-04-27
     * @returns {boolean}
     */
    dateToBigerThanFrom() {
      return this.$day(this.date.from).unix() < this.$day(this.date.to).unix()
    },
  },

  watch: {
    'dialog.from'(status) {
      if (status) {
        this.$nextTick(() => this.setPickerTitleColor())
        return false
      }
      this.$emit('reset-config')
      this.checkDate()
      this.seachData()
    },
    'dialog.to'(status) {
      if (status) {
        this.$nextTick(() => this.setPickerTitleColor())
        return false
      }
      this.$emit('reset-config')
      this.checkDate()
      this.seachData()
    },
  },

  methods: {
    /**
     * 對調 開始 結束 日期
     * @date 2021-04-27
     */
    checkDate() {
      if (!this.dateToBigerThanFrom) {
        const to = this.date.to
        const from = this.date.from

        this.date.from = to
        this.date.to = from
      }
    },

    /**
     * 搜尋資料
     * @date 2021-04-27
     */
    seachData() {
      this.checkDate()
      const time = `${this.date.from},${this.date.to}`
      this.$emit('get-data', time)
    },

    setPickerTitleColor() {
      document.querySelector('.v-picker__title').classList.add('title--text')
    },
  },
}
</script>

<style lang="scss" scoped></style>

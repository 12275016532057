var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll.self",
          value: _vm.onScroll,
          expression: "onScroll",
          modifiers: { self: true },
        },
      ],
      staticClass: "pt-56 pb-10 h-100dvh over-flow-y-scroll",
      attrs: { id: "trade" },
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pt-0 fixed bg z-index-1",
                  attrs: { cols: "12" },
                },
                [
                  !_vm.isLiffMode
                    ? _c(
                        "v-tabs",
                        {
                          attrs: {
                            "background-color": "bg",
                            color:
                              _vm.theme === "dark" ? "secondary" : "primary",
                          },
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        _vm._l(_vm.tabText, function (item, index) {
                          return _c(
                            "v-tab",
                            {
                              key: index,
                              class: [_vm.dynamicStyleByClientLang[0]],
                            },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { staticClass: "mt-1 px-2 py-2 quick-time-picker" },
                    [
                      _vm._l(_vm.dataBtnText, function (item, index) {
                        return _c(
                          "v-col",
                          {
                            key: index,
                            staticClass:
                              "rounded-lg mx-1 subtitle-2 px-0 py-2 d-flex text-center justify-center align-center",
                            class: [
                              _vm.dateBtn === index
                                ? `card1 ${
                                    _vm.theme === "dark"
                                      ? "secondary--text"
                                      : "primary--text"
                                  }`
                                : "card1 subtitle--text",
                            ],
                            style: {
                              border:
                                _vm.dateBtn === index
                                  ? `1px solid ${_vm.dynamicDateTabBorderColor} !important`
                                  : "",
                              background: _vm.theme === "light" ? "white" : "",
                            },
                            on: {
                              click: function ($event) {
                                _vm.changeData(...Object.keys(item), index)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item[Object.keys(item)]) + " ")]
                        )
                      }),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "rounded-lg mx-1 subtitle-2 px-0 py-2 d-flex justify-center align-center text-center",
                          class: [
                            _vm.dateBtn === 4
                              ? `card1 ${
                                  _vm.theme === "dark"
                                    ? "secondary--text"
                                    : "primary--text"
                                }`
                              : "card1 subtitle--text",
                          ],
                          style: {
                            border:
                              _vm.dateBtn === 4
                                ? `1px solid ${_vm.dynamicDateTabBorderColor} !important`
                                : "",
                            background:
                              _vm.theme === "light" ? "white" : "card1",
                          },
                          on: { click: _vm.customDate },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("myCenter.customize")) + " "
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { class: [_vm.dynamicStyleByClientLang[1]], attrs: { id: "data" } },
            [
              _c(
                "v-col",
                { staticClass: "pb-2 pt-2", attrs: { cols: "12" } },
                [
                  _vm.dateBtn === 4
                    ? _c("CustomizeDateTemplate3", {
                        on: {
                          "get-data": _vm.customDateGetRecord,
                          "reset-config": _vm.resetNScroll,
                        },
                      })
                    : _vm._e(),
                  _c("p", { staticClass: "caption mb-0 comment--text" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("myCenter.moreRecordMessage")) + " "
                    ),
                  ]),
                ],
                1
              ),
              _vm.recordData.length === 0
                ? [
                    _c(
                      "v-col",
                      { staticClass: "mt-10", attrs: { cols: "12" } },
                      [
                        _c("NodataTemplate3", {
                          attrs: { text: _vm.$t("global.noRecords") },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _vm._l(_vm.recordData, function (item) {
                      return _c(
                        "v-col",
                        {
                          key: item._id,
                          staticClass: "py-1 caption",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "card1 rounded pa-4 p-relative" },
                            [
                              _c(
                                "div",
                                { staticClass: "subtitle--text" },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("myCenter.no")) + " "
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "ml-4",
                                      attrs: { id: item.order_no },
                                    },
                                    [_vm._v(" " + _vm._s(item.order_no) + " ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        height: "24",
                                        width: "24",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyNumber(item.order_no)
                                        },
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "primary--text",
                                        attrs: {
                                          data: require("@icon/copy.svg"),
                                          width: "16",
                                          height: "16",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "subtitle--text" }, [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("global.amount")) + " "
                                  ),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-4",
                                    attrs: { id: item.order_no },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.status !== "done" &&
                                            item.expected_amount
                                            ? item.expected_amount
                                            : item.amount
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm.tab === 1
                                ? _c("div", { staticClass: "subtitle--text" }, [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("myCenter.bankAccount")
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { id: item.order_no },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.defray_name) + " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _c(
                                    "div",
                                    { staticClass: "subtitle--text" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("myCenter.tradeType")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _vm.tab === 0
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "ml-4",
                                                attrs: { id: item.order_no },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.paytype_name) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _vm.tab === 2
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "ml-4",
                                                attrs: { id: item.order_no },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.from_wallet) +
                                                    " "
                                                ),
                                                _c("Icon", {
                                                  staticClass:
                                                    "mx-1 primary--text",
                                                  attrs: {
                                                    data: require("@icon/arrow__right.svg"),
                                                    width: "16",
                                                    height: "16",
                                                  },
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.to_wallet) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                              _vm.tab !== 2 &&
                              _vm.statusColor(item.status) === "danger"
                                ? _c("div", { staticClass: "pt-1" }, [
                                    _c(
                                      "span",
                                      { staticClass: "subtitle--text" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("global.memo")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ml-4",
                                        attrs: { id: item.order_no },
                                      },
                                      [_vm._v(" " + _vm._s(item.memo) + " ")]
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "pt-1 subtitle--text" },
                                [
                                  _c(
                                    "span",
                                    [
                                      _c("Icon", {
                                        staticClass: "subtitle--text",
                                        attrs: {
                                          data: require("@icon/date.svg"),
                                          width: "13",
                                          height: "13",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "ml-3" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm
                                            .$day(item.trans_at)
                                            .format("YYYY/MM/DD HH:mm")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "status-btn p-absolute caption",
                                  attrs: {
                                    outlined: "",
                                    height: "24",
                                    color: _vm.statusColor(item.status),
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t(`global.${item.status}`)) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    _c(
                      "v-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.scrollBottom.progress,
                            expression: "scrollBottom.progress",
                          },
                        ],
                        staticClass: "text-center pb-0",
                        attrs: { cols: "12" },
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "primary" },
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Toolbar from '@/components/base/Toolbar'
import ToolbarTemplate3 from '@/components/base/template/ToolbarTemplate3'
import ToolbarTemplate1 from '@/components/base/template/ToolbarTemplate1'
import Select from '@/components/dialog/Select'
import Nodata from '@/components/base/Nodata.vue'
import NodataTemplate3 from '@/components/base/Nodata-template3.vue'
import Customize from '../CustomizeDate'
import CustomizeTemplate3 from '../CustomizeDateTemplate3'

import { mapActions, mapGetters } from 'vuex'
import { timezone } from '@/utils/day'
import { copyNumber } from '@/utils/copy'
import { resStatus } from '@/utils/resUtils'
import { isScrollToBottom, scrollBottomFunc } from '@/utils/scrollToBottom'

export default {
  components: {
    Toolbar,
    Select,
    Nodata,
    Customize,
    CustomizeTemplate3,
    ToolbarTemplate3,
    ToolbarTemplate1,
    NodataTemplate3,
  },

  data: () => ({
    dateBtn: 0,
    activeVendor: '',

    form: {
      vendor_code: 'all', // 產品商代號， 全部 : all，個家產品商例如：pg, gps
      trans_at: '', // 2020-07-01,2020-07-20	起始時間,結束時間
      timezone: '',
      page: 1,
    },

    vendorList: [],
    dialog: false,

    resData: [],

    scrollBottom: {
      status: true,
      ing: false,
      progress: false,
    },

    // 有效投注總額
    validBetTotal: '--',

    // 紀錄最末頁
    lastPage: null,
  }),

  computed: {
    ...mapGetters(['clientLang', 'currentDevice', 'theme']),

    dataBtnText() {
      return [
        { 0: this.$t('myCenter.today') },
        { 1: this.$t('myCenter.yesterday') },
        { 6: this.$t('myCenter.Last7days') },
        { 29: this.$t('myCenter.Last30days') },
      ]
    },

    dynamicStyleByClientLang() {
      const widthAry = ['vi-VN', 'en', 'ph']
      const width = widthAry.includes(this.clientLang)
      return width ? 'pt-65' : 'pt-50'
    },

    primaryClr() {
      return this.$vuetify.theme.themes[this.theme]?.primary
    },

    secondaryClr() {
      return this.$vuetify.theme.themes[this.theme]?.secondary
    },
  },

  watch: {
    /**
     * 監聽目前搜尋的 vendor 項目
     * @date 2021-03-19
     * @param {string}} newActiveVendor 目前選擇的 遊戲名稱
     */
    activeVendor(newActiveVendor) {
      const matchItem = this.vendorList.filter(
        (item, index) => Object.keys(item)[0] === newActiveVendor,
      )[0]

      this.form.vendor_code = matchItem[Object.keys(matchItem)]
      this.getWagerRecord()
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('global.wager') + this.$t('global.record'))
    this.getVendorBrands()
    this.form.timezone = timezone()
    this.setDate(0)
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_wager_record',
      'get_vendor_brands',
      'show_alert',
    ]),

    tabClr(index) {
      if (this.dateBtn === index) {
        if (this.theme === 'dark') {
          return {
            border: `1px solid ${this.secondaryClr} !important`,
            color: this.secondaryClr,
          }
        }
        if (this.theme === 'light') {
          return {
            border: `1px solid ${this.primaryClr} !important`,
            color: this.primaryClr,
          }
        }
      }
    },

    /**
     * 快捷日期選擇功能
     * @date 2021-03-17
     * @param {number} dayNum 日期區間
     * @param {number} index 按鍵索引
     */
    changeData(dayNum, index) {
      if (this.dateBtn === index) return false
      this.resetNScroll()

      this.dateBtn = index
      this.setDate(dayNum)
      this.getWagerRecord()

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },

    /**
     * 設置時間 (起始時間,結束時間)
     * @date 2021-03-17
     * @param {number}} dayNum 與今天相隔的天數
     */
    setDate(dayNum) {
      const today = this.$day().format('YYYY-MM-DD') // 今天日期

      const beforeDay = this.$day()
        .subtract(dayNum, 'day')
        .format('YYYY-MM-DD')

      // 如果為昨日
      if (dayNum === '1') {
        this.form.trans_at = `${beforeDay},${beforeDay}`
        return false
      }

      this.form.trans_at = `${beforeDay},${today}`
    },

    /**
     * 如果成功取得投注紀錄
     * @date 2021-03-19
     * @param {object}} data 投注紀錄
     */
    getWagerRecordSuccess({ betlogs, valid_bet_total }) {
      // 有
      this.validBetTotal = valid_bet_total

      const recordData = betlogs.data
      this.lastPage = betlogs.last_page

      if (this.form.page === 1) {
        this.resData = []
        return (this.resData = recordData)
      }

      if (recordData.length === 0) {
        this.scrollBottom.status = false
        this.scrollBottom.progress = false
        this.form.page = this.form.page - 1
        return (this.scrollBottom.ing = false)
      }

      // 如果是滾動加載
      setTimeout(() => {
        this.resData = [...this.resData, ...recordData]
        this.scrollBottom.progress = false
      }, 800)
    },

    /**
     * 取得投注紀錄
     * @date 2021-03-19
     */
    async getWagerRecord(time) {
      if (time) this.form.trans_at = time

      const res = await this.get_wager_record(this.form)
      resStatus(res, this.getWagerRecordSuccess)
    },

    /**
     * 取得所有遊戲資料
     * @date 2021-03-19
     */
    async getVendorBrands() {
      const vendorBrands = await this.get_vendor_brands()
      this.vendorList = vendorBrands
      this.activeVendor = Object.keys(vendorBrands[0])[0]
    },

    /**
     * 篩選指定遊戲紀錄
     * @date 2021-03-19
     * @param {string}} vendorText 遊戲名稱
     */
    selectVendor(vendorText) {
      this.resetBtoomSetting()
      this.activeVendor = vendorText
      this.dialog = false
    },

    copyNumber(id) {
      copyNumber(id)
    },

    customDate() {
      if (this.dateBtn === 4) return false
      this.changeData(0, 4)
    },

    resetNScroll() {
      this.$scrollToTop()
      this.resetBtoomSetting()
    },

    /**
     * 重置原始狀態 bottom
     * @date 2021-05-03
     */
    resetBtoomSetting() {
      this.resData = []
      this.form.page = 1
      this.scrollBottom.status = true
      this.scrollBottom.ing = false
      this.scrollBottom.progress = false
    },

    /**
     * 設置utils 閉包
     * @date 2021-05-03
     * @returns {any}
     */
    setBottomFun() {
      const bottom = scrollBottomFunc({
        form: this.form,
        scrollData: this.scrollBottom,
        func: () => this.getWagerRecord(),
      })
      return bottom
    },

    /**
     * 底部加載功能
     * @date 2021-05-03
     * @param {any} e
     * @returns {any}
     */
    onScroll(e) {
      if (!isScrollToBottom(e)) {
        return false
      }

      // 已經是最末頁
      if (this.form.page >= this.lastPage) return false

      if (isScrollToBottom(e) && !this.scrollBottom.status) {
        if (this.scrollBottom.ing) return false
        return false
      }

      const bottom = this.setBottomFun()

      bottom.getData()
      setTimeout(() => bottom.reset(), 1000)
    },
  },
}

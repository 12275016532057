<template>
  <!-- 交易紀錄 -->
  <div
    id="trade"
    v-scroll.self="onScroll"
    class="pt-56 pb-10 h-100dvh over-flow-y-scroll"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="pt-0 fixed bg z-index-1"
        >
          <!-- TAB -->
          <v-tabs
            v-show="!isLiffMode"
            v-model="tab"
            background-color="bg"
            color="primary"
            grow
          >
            <v-tab
              v-for="(item, index) in tabText"
              :key="index"
              :class="[dynamicStyleByClientLang[0]]"
            >
              {{ item }}
            </v-tab>
          </v-tabs>

          <!-- 快選日期 -->
          <v-row class="mt-1 px-2 py-2 quick-time-picker">
            <v-col
              v-for="(item, index) in dataBtnText"
              :key="index"
              class="rounded-lg mx-1 subtitle-2 px-0 py-2 d-flex text-center justify-center align-center"
              :class="[
                dateBtn === index
                  ? 'secondary white--text'
                  : 'inputBg subtitle--text',
              ]"
              @click="changeData(...Object.keys(item), index)"
            >
              {{ item[Object.keys(item)] }}
            </v-col>
            <!-- 自定義 data -->
            <v-col
              class="rounded-lg mx-1 subtitle-2 px-0 py-2 d-flex justify-center align-center text-center"
              :class="[
                dateBtn === 4
                  ? 'secondary white--text'
                  : 'inputBg subtitle--text',
              ]"
              @click="customDate"
            >
              {{ $t('myCenter.customize') }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row
        id="data"
        :class="[ dynamicStyleByClientLang[1] ]"
      >
        <v-col
          cols="12"
          class="pb-2 pt-2"
        >
          <!-- 日期選取器 -->
          <Customize
            v-if="dateBtn === 4"
            @get-data="customDateGetRecord"
            @reset-config="resetNScroll"
          />

          <!-- 提醒說明文字 -->
          <p class="caption mb-0">
            {{ $t('myCenter.moreRecordMessage') }}
          </p>
        </v-col>
        <template v-if="recordData.length === 0">
          <v-col cols="12">
            <Nodata :text="$t('global.noRecords')" />
          </v-col>
        </template>

        <template v-else>
          <!-- 若有資料 -->
          <v-col
            v-for="item in recordData"
            :key="item._id"
            cols="12"
            class="py-1 caption"
          >
            <div class="card1 rounded pa-4 p-relative">
              <!-- 單號 -->
              <div>
                <span class="subtitle--text">
                  {{ $t('myCenter.no') }}
                </span>
                <span
                  :id="item.order_no"
                  class="ml-4"
                >
                  {{ item.order_no }}
                </span>

                <!-- copy BTM -->
                <v-btn
                  icon
                  height="24"
                  width="24"
                  @click="copyNumber(item.order_no)"
                >
                  <Icon
                    data="@icon/copy.svg"
                    width="16"
                    height="16"
                    class="subtitle--text"
                  />
                </v-btn>
              </div>

              <!-- 金額 -->
              <div>
                <span class="subtitle--text">
                  {{ $t('global.amount') }}
                </span>
                <span
                  :id="item.order_no"
                  class="ml-4"
                >
                  {{ item.status !== 'done' && item.expected_amount ? item.expected_amount : item.amount }}
                </span>
              </div>

              <!-- 取款 -->
              <div v-if="tab === 1">
                <!-- 帳戶 -->
                <span class="subtitle--text">
                  {{ $t('myCenter.bankAccount') }}
                </span>

                <span
                  :id="item.order_no"
                  class="ml-4"
                >
                  {{ item.defray_name }}
                </span>
              </div>

              <!-- 交易類型 (存款 / 轉換) -->
              <div v-else>
                <span class="subtitle--text">
                  {{ $t('myCenter.tradeType') }}
                </span>

                <!-- FOR 存款 -->
                <template v-if="tab === 0">
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.paytype_name }}
                  </span>
                </template>

                <!-- FOR 額度轉換 -->
                <template v-if="tab === 2">
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.from_wallet }}
                    <Icon
                      data="@icon/arrow__right.svg"
                      width="16"
                      height="16"
                      class="mx-1 primary--text"
                    />
                    {{ item.to_wallet }}
                  </span>
                </template>
              </div>

              <!-- MEMO 備註 -->
              <div
                v-if="tab !== 2 && statusColor(item.status) === 'danger'"
                class="pt-1"
              >
                <!-- 備註 -->
                <span class="subtitle--text">
                  {{ $t('global.memo') }}
                </span>

                <span
                  :id="item.order_no"
                  class="ml-4"
                >
                  {{ item.memo }}
                </span>
              </div>

              <!-- 日期 -->
              <div class="pt-1">
                <span class="subtitle--text">
                  <Icon
                    data="@icon/date.svg"
                    width="13"
                    height="13"
                    class="subtitle--text"
                  />
                </span>
                <span class="ml-3">
                  {{ $day(item.trans_at).format('YYYY/MM/DD HH:mm') }}
                </span>
              </div>

              <!-- 狀態 -->
              <v-btn
                outlined
                height="24"
                class="status-btn p-absolute caption"
                :color="statusColor(item.status)"
              >
                {{ $t(`global.${item.status}`) }}
              </v-btn>
            </div>
          </v-col>

          <!--  Bottom LOADING  -->
          <v-col
            v-show="scrollBottom.progress"
            cols="12"
            class="text-center pb-0"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import tradeMixin from '../mixins/tradeMixin'

export default {
  mixins: [tradeMixin],
}
</script>

<style lang="scss" scoped>
.status-btn {
	right: 16px;
	bottom: 16px;
}

.pt-120{
  padding-top: 120px;
}

.pt-40px {
  padding-top: 40px;
}
.pt-60px {
  padding-top: 60px;
}
</style>

import Nodata from '@/components/base/Nodata.vue'
import NodataTemplate3 from '@/components/base/Nodata-template3.vue'
import Customize from '../CustomizeDate'
import CustomizeDateTemplate3 from '../CustomizeDateTemplate3'

import { timezone } from '@/utils/day'
import { mapActions, mapGetters } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { copyNumber } from '@/utils/copy'
import { isScrollToBottom, scrollBottomFunc } from '@/utils/scrollToBottom'

export default {

  components: { Nodata, Customize, NodataTemplate3, CustomizeDateTemplate3 },

  data: () => ({
    tab: '',
    dateBtn: 0,

    form: {
      trans_type: 'deposit', // 交易類型 deposit, withdrawal
      trans_at: '', // 2020-07-01,2020-07-20	起始時間,結束時間
      timezone: '',
      page: 1,
    },

    recordData: [],

    scrollBottom: {
      status: true,
      ing: false,
      progress: false,
    },

    lastPage: null,
  }),

  computed: {
    ...mapGetters(['clientLang', 'theme', 'isLiffMode']),

    secondaryClr() {
      return this.$vuetify.theme.themes[this.theme]?.secondary
    },
    primaryClr() {
      return this.$vuetify.theme.themes[this.theme]?.primary
    },

    dynamicDateTabBorderColor() {
      if (this.theme === 'dark') return this.secondaryClr
      return this.primaryClr
    },

    tabText() {
      return [
        this.$t('global.deposit'),
        this.$t('global.withdraw'),
        this.$t('global.transfer'),
      ]
    },

    dataBtnText() {
      return [
        { 0: this.$t('myCenter.today') },
        { 1: this.$t('myCenter.yesterday') },
        { 6: this.$t('myCenter.Last7days') },
        { 29: this.$t('myCenter.Last30days') },
      ]
    },

    // 因應前端語系 layout 動態調整
    dynamicStyleByClientLang() {
      const widthAry = ['vi-VN', 'en', 'ph']
      const width = widthAry.includes(this.clientLang)
      return [
        { caption: width },
        width ? (this.isLiffMode ? 'pt-60px' : 'pt-120') : (this.isLiffMode ? 'pt-40px' : 'pt-100'),
      ]
    },
  },

  watch: {
    tab() {
      this.changeData(0, 0)
    },
  },

  mounted() {
    this.set_toolbar_title(
      this.$t('global.transaction') + this.$t('global.record'),
    )

    this.form.timezone = timezone()
    this.setDate(0)

    // 如果是 liff 模式，額度轉換 tab
    if (this.isLiffMode) {
      this.tab = 2
    }
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_paymentLog',
      'get_transaction_record',
      'show_alert',
    ]),

    /**
     * 功能入口，決定執行的 api
     * @date 2021-03-17
     * @param {number} nowIndex tab 索引，判斷執行的功能
     */
    main(nowIndex) {
      switch (nowIndex) {
        case 0:
          this.getPaymentLog('deposit')
          break

        case 1:
          this.getPaymentLog('withdrawal')
          break

        case 2:
          this.getTransationRecord(
            'gameTransferIn,gameTransferOut,transferIn,transferOut,boDeposit,boWithdrawal',
          )
          break

        default:
          break
      }
    },

    /**
     * 設置時間 (起始時間,結束時間)
     * @date 2021-03-17
     * @param {number}} dayNum 與今天相隔的天數
     */
    setDate(dayNum) {
      const today = this.$day().format('YYYY-MM-DD') // 今天日期
      const beforeDay = this.$day()
        .subtract(dayNum, 'day')
        .format('YYYY-MM-DD')

      // 如果為昨日
      if (dayNum === '1') {
        this.form.trans_at = `${beforeDay},${beforeDay}`
        return false
      }

      const time = `${beforeDay},${today}`
      this.form.trans_at = time
    },

    /** (成功) 取得 存款/取款 紀錄
     * 描述
     * @date 2021-03-19
     * @param {any} data
     * @returns {any}
     */
    getPaymentLogSuccess({ logs }) {
      const data = logs.data
      this.lastPage = logs.last_page

      if (this.form.page === 1) {
        this.recordData = []
        return (this.recordData = data)
      }

      if (data.length === 0) {
        this.scrollBottom.status = false
        this.scrollBottom.progress = false
        this.form.page = this.form.page - 1
        return (this.scrollBottom.ing = false)
      }

      // 如果是滾動加載
      setTimeout(() => {
        this.recordData = [...this.recordData, ...data]
        this.scrollBottom.progress = false
      }, 800)
    },

    /**
     * 取得 存款/取款 紀錄
     * @date 2021-03-17
     * @param {string}} trans_type 存款/取款
     */
    async getPaymentLog(trans_type) {
      this.form.trans_type = trans_type
      const res = await this.get_paymentLog(this.form)
      resStatus(res, this.getPaymentLogSuccess)
    },

    /**
     * 取得 額度轉換 紀錄
     * @date 2021-03-17
     * @param {string}} trans_type 存款/取款
     */
    async getTransationRecord(trans_type) {
      this.form.trans_type = trans_type
      const res = await this.get_transaction_record(this.form)
      resStatus(res, this.getPaymentLogSuccess)
    },

    /**
     * 快捷日期選擇功能
     * @date 2021-03-17
     * @param {number} dayNum 日期區間
     * @param {number} index 按鍵索引
     */
    changeData(dayNum, index) {
      this.resetNScroll()
      this.dateBtn = index
      this.setDate(dayNum)
      this.main(this.tab)
    },

    /**
     * 狀態 動態換色
     * @date 2021-04-28
     * @param {string} status 狀態
     */
    statusColor(status) {
      const success = ['done', 'complete']
      const pending = ['wait', 'pending', 'verify']
      const fail = ['failed', 'close', 'return']

      if (success.includes(status)) return 'success'
      else if (pending.includes(status)) return 'warning'
      else return 'danger'
    },

    copyNumber(id) {
      copyNumber(id)
    },

    /**
     * 使用自定義搜尋資料
     * @date 2021-04-28
     */
    customDate() {
      if (this.dateBtn === 4) return false
      this.changeData(0, 4)
    },

    /**
     * 自定義 取得資料方法
     * @date 2021-04-28
     * @param {string} timeRang 時間範圍 '2021-04-01,2021-04-28'
     */
    customDateGetRecord(timeRang) {
      this.form.trans_at = timeRang
      this.main(this.tab)
    },

    resetNScroll() {
      this.$scrollToTop()
      this.resetBtoomSetting()
    },

    /**
     * 重置原始狀態 bottom
     * @date 2021-05-03
     */
    resetBtoomSetting() {
      this.recordData = []
      this.form.page = 1
      this.scrollBottom.status = true
      this.scrollBottom.ing = false
      this.scrollBottom.progress = false
    },

    /**
     * 設置utils 閉包
     * @date 2021-05-03
     * @returns {any}
     */
    setBottomFun() {
      const bottom = scrollBottomFunc({
        form: this.form,
        scrollData: this.scrollBottom,
        func: () => this.main(this.tab),
      })
      return bottom
    },

    /**
     * 底部加載功能
     * @date 2021-05-03
     * @param {any} e
     * @returns {any}
     */
    onScroll(e) {
      if (!isScrollToBottom(e)) {
        return false
      }

      if (this.form.page >= this.lastPage) return false

      if (isScrollToBottom(e) && !this.scrollBottom.status) {
        if (this.scrollBottom.ing) return false
        return false
      }

      const bottom = this.setBottomFun()
      bottom.getData()
      setTimeout(() => bottom.reset(), 1000)
    },
  },
}

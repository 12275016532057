var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll.self",
          value: _vm.onScroll,
          expression: "onScroll",
          modifiers: { self: true },
        },
      ],
      staticClass: "pt-56 vh-100 overflow-y-auto",
      class: _vm.currentDevice === "ios" ? "pb-72" : "pb-10",
      attrs: { id: "wager" },
    },
    [
      _c(
        "Toolbar",
        [
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", text: "", color: "icon" },
              on: {
                click: function ($event) {
                  _vm.dialog = true
                },
              },
              slot: "btn",
            },
            [
              _c("Icon", {
                staticClass: "icon--text",
                attrs: {
                  data: require("@icon/filterIcon.svg"),
                  width: "24px",
                  height: "24px",
                },
              }),
              _c("span", { staticClass: "icon--text" }, [
                _vm._v(" " + _vm._s(_vm.activeVendor) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "z-index-1 fixed bg", attrs: { cols: "12" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-1 px-2 pb-2 quick-time-picker" },
                        [
                          _vm._l(_vm.dataBtnText, function (item, index) {
                            return _c(
                              "v-col",
                              {
                                key: index,
                                staticClass:
                                  "rounded-lg mx-1 caption px-0 py-2 text-center d-flex align-center justify-center",
                                class: [
                                  _vm.dateBtn === index
                                    ? "secondary white--text"
                                    : "inputBg subtitle--text",
                                ],
                                on: {
                                  click: function ($event) {
                                    _vm.changeData(...Object.keys(item), index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(item[Object.keys(item)]) + " "
                                ),
                              ]
                            )
                          }),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "rounded-lg mx-1 caption px-0 py-2 text-center d-flex align-center justify-center",
                              class: [
                                _vm.dateBtn === 4
                                  ? "secondary white--text"
                                  : "inputBg subtitle--text",
                              ],
                              on: { click: _vm.customDate },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("myCenter.customize")) + " "
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-1", class: [_vm.dynamicStyleByClientLang] },
            [
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { cols: "12" } },
                [
                  _vm.dateBtn === 4
                    ? _c("Customize", {
                        on: {
                          "get-data": _vm.getWagerRecord,
                          "reset-config": _vm.resetNScroll,
                        },
                      })
                    : _vm._e(),
                  _c("p", { staticClass: "caption mb-0 pb-0 comment--text" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("myCenter.wagerDelayMessage")) + " "
                    ),
                  ]),
                  _c(
                    "p",
                    { staticClass: "d-flex align-center" },
                    [
                      _c("Icon", {
                        staticClass: "icon--text",
                        attrs: {
                          data: require("@icon/betValid.svg"),
                          width: "16",
                          height: "16",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass:
                            "primary--text subtitle-2 font-weight-medium mx-2",
                        },
                        [_vm._v(" " + _vm._s(_vm.validBetTotal) + " ")]
                      ),
                      _c("span", { staticClass: "caption title--text" }, [
                        _vm._v(
                          " (" + _vm._s(_vm.$t("global.validBetTotal")) + ") "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.resData.length === 0
                ? [
                    _c(
                      "v-col",
                      { staticClass: "mt-80px", attrs: { cols: "12" } },
                      [
                        _c("Nodata", {
                          attrs: { text: _vm.$t("global.noRecords") },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "v-col",
                      { staticClass: "pt-0", attrs: { cols: "12" } },
                      _vm._l(_vm.resData, function (vendor) {
                        return _c(
                          "div",
                          {
                            key: vendor._id,
                            staticClass: "card1 rounded my-2 record caption",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "record__title rounded pl-4 title--text card2 py-1",
                              },
                              [
                                _c("span", [
                                  _vm._v(" " + _vm._s(vendor.vendor) + " "),
                                ]),
                                _c("span", { staticClass: "primary--text" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(vendor.game.split("/")[0]) +
                                      " "
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(vendor.game.split("/")[1]) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "pl-4 py-3 p-relative" },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "subtitle--text" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("myCenter.no")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ml-4",
                                        attrs: {
                                          id: "a" + vendor.parent_bet_id,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(vendor.parent_bet_id) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          height: "24",
                                          width: "24",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyNumber(
                                              "a" + vendor.parent_bet_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("Icon", {
                                          staticClass: "subtitle--text",
                                          attrs: {
                                            data: require("@icon/copy.svg"),
                                            width: "16",
                                            height: "16",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "subtitle--text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("myCenter.wager")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("span", { staticClass: "ml-4" }, [
                                    _vm._v(
                                      " " + _vm._s(vendor.bet_amount) + " "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "py-1" }, [
                                  _c(
                                    "span",
                                    { staticClass: "subtitle--text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("myCenter.payoff")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("span", { staticClass: "ml-4" }, [
                                    _vm._v(
                                      " " + _vm._s(vendor.win_amount) + " "
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "subtitle--text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("myCenter.validWager")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("span", { staticClass: "ml-4" }, [
                                    _vm._v(
                                      " " + _vm._s(vendor.translate) + " "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "pt-1" }, [
                                  _c(
                                    "span",
                                    { staticClass: "subtitle--text" },
                                    [
                                      _c("Icon", {
                                        staticClass: "subtitle--text",
                                        attrs: {
                                          data: require("@icon/date.svg"),
                                          width: "13",
                                          height: "13",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "ml-3" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm
                                            .$day(vendor.created_at)
                                            .format("YYYY/MM/DD HH:mm")
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "vendor__status p-absolute caption",
                                    attrs: {
                                      outlined: "",
                                      height: "24",
                                      width: "61",
                                      color:
                                        vendor.status === "Settled"
                                          ? "success"
                                          : "danger",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(`myCenter.${vendor.status}`)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "v-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.scrollBottom.progress,
                            expression: "scrollBottom.progress",
                          },
                        ],
                        staticClass: "text-center pb-0",
                        attrs: { cols: "12" },
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "primary" },
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "Select",
        { attrs: { status: _vm.dialog, height: 344, width: 280 } },
        [
          _c(
            "v-row",
            {
              staticClass: "p-sticky top-0 bg",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-h6 title--text px-6 py-4 p-sticky",
                  attrs: { cols: "12" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("myCenter.chooseBrand")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { slot: "list" }, slot: "list" },
            _vm._l(_vm.vendorList, function (item, index) {
              return _c(
                "v-col",
                {
                  key: index,
                  class: [
                    "px-6",
                    Object.keys(item)[0] === _vm.activeVendor
                      ? "secondary"
                      : "bg",
                    "rounded",
                  ],
                  attrs: { cols: "12" },
                  on: {
                    click: function ($event) {
                      _vm.selectVendor(Object.keys(item)[0])
                    },
                  },
                },
                [_vm._v(" " + _vm._s(Object.keys(item)[0]) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll.self",
          value: _vm.onScroll,
          expression: "onScroll",
          modifiers: { self: true },
        },
      ],
      staticClass: "pt-56 pb-10 vh-100 overflow-y-auto",
      attrs: { id: "prize" },
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pt-0 fixed bg z-index-1",
                  attrs: { cols: "12" },
                },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "background-color": "bg",
                        color: "primary",
                        grow: "",
                      },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _vm._l(_vm.tabText, function (item, index) {
                        return [
                          _c(
                            "v-tab",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    (_vm.showPrizeBonus && index === 0) ||
                                    index !== 0,
                                  expression:
                                    "showPrizeBonus && index === 0 || index !== 0",
                                },
                              ],
                              key: index,
                            },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-1 px-2 py-2 quick-time-picker" },
                    [
                      _vm._l(_vm.dataBtnText, function (item, index) {
                        return _c(
                          "v-col",
                          {
                            key: index,
                            staticClass:
                              "rounded-lg mx-1 subtitle-2 px-0 py-2 text-center d-flex align-center justify-center",
                            class: [
                              _vm.dateBtn === index
                                ? "secondary white--text"
                                : "inputBg subtitle--text",
                            ],
                            on: {
                              click: function ($event) {
                                _vm.changeData(...Object.keys(item), index)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item[Object.keys(item)]) + " ")]
                        )
                      }),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "rounded-lg mx-1 subtitle-2 px-0 py-2 text-center d-flex align-center justify-center",
                          class: [
                            _vm.dateBtn === 4
                              ? "secondary white--text"
                              : "inputBg subtitle--text",
                          ],
                          on: { click: _vm.customDate },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("myCenter.customize")) + " "
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { class: [_vm.dynamicStyleByClientLang] },
            [
              _c(
                "v-col",
                { staticClass: "pb-2 pt-2", attrs: { cols: "12" } },
                [
                  _vm.dateBtn === 4
                    ? _c("Customize", {
                        on: {
                          "get-data": _vm.customDateGetRecord,
                          "reset-config": _vm.resetNScroll,
                        },
                      })
                    : _vm._e(),
                  _c("p", { staticClass: "caption mb-0" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("myCenter.moreRecordMessage")) + " "
                    ),
                  ]),
                ],
                1
              ),
              _vm.form.type === "bonus"
                ? _c(
                    "v-col",
                    { staticClass: "pb-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog.status = true
                            },
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "icon--text",
                            attrs: {
                              data: require("@icon/filterIcon.svg"),
                              width: "24px",
                              height: "24px",
                            },
                          }),
                          _c("span", { staticClass: "ml-1 title--text" }, [
                            _vm._v(" " + _vm._s(_vm.subTypeList.active) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.recordData.length === 0
                ? [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("Nodata", {
                          attrs: { text: _vm.$t("global.noRecords") },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _vm._l(_vm.recordData, function (item) {
                      return _c(
                        "v-col",
                        {
                          key: item._id,
                          staticClass: "py-1 caption title--text",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "card1 rounded pa-4 p-relative" },
                            [
                              _vm.tab === 0
                                ? [
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "subtitle--text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("myCenter.prizeName")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-4",
                                          attrs: { id: item.order_no },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.present_content) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "subtitle--text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("myCenter.prizeType")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-4",
                                          attrs: { id: item.order_no },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.present_type) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                : _vm.tab === 1
                                ? [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "subtitle--text" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("myCenter.no")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "ml-4",
                                            attrs: { id: item.order_id },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.order_id) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              height: "24",
                                              width: "24",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.copyNumber(
                                                  item.order_id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("Icon", {
                                              staticClass: "subtitle--text",
                                              attrs: {
                                                data: require("@icon/copy.svg"),
                                                width: "16",
                                                height: "16",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "subtitle--text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("global.amount")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-4",
                                          attrs: { id: item.order_no },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.amount) + " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "pt-1" }, [
                                      _c(
                                        "span",
                                        { staticClass: "subtitle--text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("myCenter.type")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-4",
                                          attrs: { id: item.order_no },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.sub_type) + " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                : [
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "subtitle--text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("myCenter.invitedName")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-4",
                                          attrs: { id: item.order_no },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.invited_name) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "subtitle--text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "myCenter.invitedFirstDeposit"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-4",
                                          attrs: { id: item.order_no },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.invited_first_deposit
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "subtitle--text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("myCenter.invitedRebate")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-4",
                                          attrs: { id: item.order_no },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.invited_rebate) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("span", { staticClass: "ml-1" }, [
                                        _vm._v(
                                          "(" +
                                            _vm._s(item.invited_rebate_status) +
                                            ")"
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "subtitle--text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "myCenter.invitedRebateStatus"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-4",
                                          attrs: { id: item.order_no },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.invited_bonus) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                              _c("div", { staticClass: "pt-1" }, [
                                _c(
                                  "span",
                                  { staticClass: "subtitle--text" },
                                  [
                                    _c("Icon", {
                                      attrs: {
                                        data: require("@icon/date.svg"),
                                        width: "13",
                                        height: "13",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.form.type === "recommend"
                                  ? _c("span", { staticClass: "ml-3" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm
                                              .$day(item.create_date)
                                              .format("YYYY/MM/DD")
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", { staticClass: "ml-3" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm
                                              .$day(item.trans_at)
                                              .format("YYYY/MM/DD HH:mm")
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "status-btn p-absolute caption",
                                  attrs: {
                                    outlined: "",
                                    height: "24",
                                    width: "61",
                                    color: _vm.statusColor(item.status),
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t(`global.${item.status}`)) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    }),
                    _c(
                      "v-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.scrollBottom.progress,
                            expression: "scrollBottom.progress",
                          },
                        ],
                        staticClass: "text-center pb-0",
                        attrs: { cols: "12" },
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "primary" },
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "Select",
        { attrs: { status: _vm.dialog.status, height: 344, width: 280 } },
        [
          _c(
            "v-row",
            {
              staticClass: "p-sticky top-0 bg",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-h6 title--text px-6 py-4 p-sticky",
                  attrs: { cols: "12" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("form.missType")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { slot: "list" }, slot: "list" },
            _vm._l(_vm.subTypeList.text, function (item, index) {
              return _c(
                "v-col",
                {
                  key: index,
                  class: [
                    "px-6",
                    item === _vm.subTypeList.active ? "secondary" : "bg",
                    "rounded",
                  ],
                  attrs: { cols: "12" },
                  on: {
                    click: function ($event) {
                      return _vm.setSubtypeActive(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }